import React, { useState, useEffect, useRef, createContext, useMemo, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import GridPanel from "../gridPanel"
import ReportPanel from '../reportPanel';
import FormsPanel from '../formsPanel';
import PivotsPanel from '../pivotsPanel';
import MapPanel from '../Maps/mapPanel';
import CardsPanel from '../cardsPanel';
import ThreePositionLayoutCard from '../threePositionLayoutCard';
import FivePositionLayoutCard from '../fivePositionLayoutCard';
import TenPositionLayoutCard from '../tenPositionLayoutCard';
import OnePositionLayoutCard from '../onePositionLayoutCard';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';


const NewPanelsContent = ({ panel, panelJSON, screenList, sidebarScreenParameters, dynamicGridHeight }) => {
    const client = useApolloClient();
    const default_master_guid = useSelector(state => state.mainReducer.default_master_guid);

    const isValidDate = (replacement) => {
        // Try to parse the string with Moment
        const parsedDate = moment(replacement, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(]Z[)]', true);

        // Check if the parsing was successful and the date is valid
        return parsedDate.isValid();
    };



    const card = () => {

        switch (panelJSON && panelJSON.sys_panels_types_guid) {
            //grid
            case 'ed9d4d8c-f911-487b-8f0f-49356ec67953':
                if (sidebarScreenParameters) {
                    let screenParameters = panelJSON.query_parameters
                    var gridQuery = panelJSON.query
                    let rowCountQuery = panelJSON.row_count
                    for (let i of screenParameters) {
                        let replacement = sidebarScreenParameters && sidebarScreenParameters[i]
                        if (typeof replacement === 'string') {
                            replacement = '\"' + replacement + '\"'
                        }
                        else if (replacement !== null || replacement?.length > 1) {
                            if (isValidDate(replacement)) {
                                replacement = moment(replacement, 'yyyy-MM-dd', true).format()
                                replacement = replacement.slice(0, -6)
                            }
                            replacement = '\"' + replacement + '\"'
                        }

                        gridQuery = gridQuery.replace(`@${i}`, replacement)
                        rowCountQuery = rowCountQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    gridQuery = gridQuery.substring(1, gridQuery.length - 1)
                    return <GridPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} gridQuery={gridQuery} queryName={queryName} dynamicGridHeight={dynamicGridHeight} rowCountQuery={rowCountQuery} />
                }
                else {
                    var gridQuery = panelJSON.query
                    let rowCountQuery = panelJSON.row_count
                    let replacement;
                    for (let i of screenList) {
                        if (i.required && i.param_value === null) {
                            gridQuery = "{}"
                            rowCountQuery = "{}"
                            break;
                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {
                            replacement = i.param_value
                        }
                        gridQuery = gridQuery.replace(`@${i.param}`, replacement)
                        rowCountQuery = rowCountQuery.replace(`@${i.param}`, replacement)
                    }
                    gridQuery = gridQuery.substring(1, gridQuery.length - 1)
                    rowCountQuery = rowCountQuery.substring(1, rowCountQuery.length - 1)
                    return <GridPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} gridQuery={gridQuery} dynamicGridHeight={dynamicGridHeight} rowCountQuery={rowCountQuery} />
                }
            //report
            case 'e5f1e3aa-39fe-4ed6-b8f7-3a7724709671':
                let reportQuery = panelJSON.query.replace('@v_locale', 'en')
                reportQuery = reportQuery.substring(1, reportQuery.length - 1)
                return <ReportPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} reportQuery={reportQuery} />
            //form
            case '6d6cc369-ca19-4fe2-ac19-782b282dedb9':
                if (sidebarScreenParameters) {
                    let screenParameters = panelJSON.query_parameters
                    var formsQuery = panelJSON.query
                    const animalsId = [];
                    const objectWithGuid = [];
                    for (let i of screenParameters) {
                        let replacement = sidebarScreenParameters && sidebarScreenParameters[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        formsQuery = formsQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    formsQuery = formsQuery.substring(1, formsQuery.length - 1)
                    return <FormsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} screenList={screenParameters} formsQuery={formsQuery} queryName={queryName} animalsId={animalsId} objectWithGuid={objectWithGuid} />
                }
                else {
                    var formsQuery = panelJSON.query
                    const animalsId = [];
                    const objectWithGuid = [];
                    let replacement;
                    for (let i of screenList) {
                        if (i.param.includes("guid") && panel.sys_actions_flags_guid !== "f56e5198-8c16-4668-90e9-cf1b03a205a9") {
                            if (i.param.includes("guid") && panel.sys_actions_flags_guid !== "f56e5198-8c16-4668-90e9-cf1b03a205a9") {
                                if (i.panel_parameters_mapping[0].sys_columns_guid !== null) {
                                    let copyOfI = JSON.parse(JSON.stringify(i));
                                    const defaultMasterGuidObject = default_master_guid && Array.isArray(default_master_guid)
                                        ? default_master_guid.find(item => item.param === copyOfI.param)
                                        : null;
                                    copyOfI.param_value = defaultMasterGuidObject?.param_value;
                                    animalsId.push(copyOfI);
                                } else {
                                    objectWithGuid.push(i);
                                }
                            }

                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {
                            replacement = i.param_value
                        }
                        formsQuery = formsQuery.replace(`@${i.param}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    formsQuery = formsQuery.substring(1, formsQuery.length - 1)
                    return <FormsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} animalsId={animalsId} screenList={screenList} objectWithGuid={objectWithGuid} formsQuery={formsQuery} queryName={queryName} />
                }
            //pivot
            case "2bd806f3-bb05-415f-9233-b50b3f78c921":
                if (sidebarScreenParameters) {
                    let screenParameters = panelJSON.query_parameters
                    var pivotsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = sidebarScreenParameters && sidebarScreenParameters[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        pivotsQuery = pivotsQuery.replace(`@${i}`, replacement)
                    }
                    pivotsQuery = pivotsQuery.substring(1, pivotsQuery.length - 1)
                    return <PivotsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} pivotsQuery={pivotsQuery} dynamicGridHeight={dynamicGridHeight} />
                }
                else {
                    let pivotsQuery = panelJSON.query
                    let replacement;
                    for (let i of screenList) {
                        if (i.required && i.param_value === null) {
                            pivotsQuery = "{}"
                            break;
                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {
                            replacement = i.param_value
                        }
                        pivotsQuery = pivotsQuery.replace(`@${i.param}`, replacement)
                    }
                    pivotsQuery = pivotsQuery.substring(1, pivotsQuery.length - 1)
                    return <PivotsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} pivotsQuery={pivotsQuery} dynamicGridHeight={dynamicGridHeight} />
                }
            //map
            case "3148cdd4-3540-46eb-8e9b-34ca223bbae7": {

                if (sidebarScreenParameters) {
                    let screenParameters = panelJSON.query_parameters
                    var mapsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = sidebarScreenParameters && sidebarScreenParameters[i]
                        if (typeof replacement === 'string') {
                            replacement = '\"' + replacement + '\"'
                        }
                        else if (replacement !== null || replacement?.length > 1) {
                            if (isValidDate(replacement)) {
                                replacement = moment(replacement, 'yyyy-MM-dd', true).format()
                                replacement = replacement.slice(0, -6)
                            }
                            replacement = '\"' + replacement + '\"'
                        }

                        mapsQuery = mapsQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    mapsQuery = mapsQuery.substring(1, mapsQuery.length - 1)
                    return <MapPanel mapType={panel.isOpenStreet} idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} mapsQuery={mapsQuery} queryName={queryName} dynamicGridHeight={dynamicGridHeight} />
                }
                else {
                    var mapsQuery = panelJSON.query
                    let replacement;
                    for (let i of screenList) {
                        if (i.required && i.param_value === null) {
                            mapsQuery = "{}"
                            rowCountQuery = "{}"
                            break;
                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {
                            replacement = i.param_value
                        }
                        mapsQuery = mapsQuery.replace(`@${i.param}`, replacement)
                    }
                    mapsQuery = mapsQuery.substring(1, mapsQuery.length - 1)
                    return <MapPanel mapType={panel.isOpenStreet} idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} mapsQuery={mapsQuery} dynamicGridHeight={dynamicGridHeight} />
                }
            }
            // cards
            case "8c9f53c8-6db1-4ff8-8808-0ea4d7ff739f":
                if (sidebarScreenParameters) {
                    let screenParameters = panelJSON.query_parameters
                    let cardsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = sidebarScreenParameters && sidebarScreenParameters[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        cardsQuery = cardsQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    cardsQuery = cardsQuery.substring(1, cardsQuery.length - 1)
                    return <CardsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsQuery={cardsQuery} queryName={queryName} />
                }
                else {
                    let cardsQuery = panelJSON.query
                    for (let i of screenList) {
                        let replacement = '\"' + i.param_value + '\"'
                        cardsQuery = cardsQuery.replace(`@${i.param}`, replacement)
                    }
                    cardsQuery = cardsQuery.substring(1, cardsQuery.length - 1)
                    return <CardsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsQuery={cardsQuery} queryName={queryName} />
                }

            case "cae6e007-1721-4f7d-8cff-eb9eff7cff93":
                let screenParameters = panelJSON.query_parameters
                let cardsLayoutQuery = panelJSON.query
                switch (panelJSON.card_definition[0]?.sys_panels_cards_layouts_types_guid) {
                    case ("6ba2b047-8c25-4ac8-8e6b-709afd40faf2"):
                        if (sidebarScreenParameters) {
                            for (let i of screenParameters) {
                                let replacement = sidebarScreenParameters && sidebarScreenParameters[i]
                                if (typeof replacement === 'string') {

                                    replacement = '\"' + replacement + '\"'
                                }

                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i}`, replacement)
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1)
                            return <ThreePositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} screenList={screenParameters} cardsLayoutQuery={cardsLayoutQuery} />
                        }
                        else {
                            let replacement;
                            for (let i of screenList) {
                                if (i.required && i.param_value === null) {
                                    cardsLayoutQuery = "{}"
                                    break;
                                }
                                if (i.param_value !== null) {
                                    replacement = '\"' + i.param_value + '\"'
                                }
                                else {
                                    replacement = i.param_value
                                }
                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i.param}`, replacement)
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1)
                            return <ThreePositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} screenList={screenList} cardsLayoutQuery={cardsLayoutQuery} />
                        }
                    case ("23fe9685-c10c-4488-8249-9a9e8d3620c1"):
                        if (sidebarScreenParameters) {
                            for (let i of screenParameters) {
                                let replacement = sidebarScreenParameters && sidebarScreenParameters[i];
                                if (typeof replacement === 'string') {
                                    replacement = '\"' + replacement + '\"';
                                }
                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i}`, replacement);
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1);
                            return <FivePositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} sidebarScreenParameters={sidebarScreenParameters} screenList={screenParameters} cardsLayoutQuery={cardsLayoutQuery} />;
                        } else {
                            let replacement;
                            for (let i of screenList) {
                                if (i.required && i.param_value === null) {
                                    cardsLayoutQuery = "{}"
                                    break;
                                }
                                if (i.param_value !== null) {
                                    replacement = '\"' + i.param_value + '\"'
                                }
                                else {
                                    replacement = i.param_value
                                }
                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i.param}`, replacement)
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1);
                            return <FivePositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} screenList={screenList} cardsLayoutQuery={cardsLayoutQuery} />;
                        }
                    case ("3a15b135-5584-44e8-a098-3cfb83ef4ff2"):
                        if (sidebarScreenParameters) {
                            for (let i of screenParameters) {
                                let replacement = sidebarScreenParameters && sidebarScreenParameters[i];
                                if (typeof replacement === 'string') {
                                    replacement = '\"' + replacement + '\"';
                                }
                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i}`, replacement);
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1);
                            return <TenPositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} sidebarScreenParameters={sidebarScreenParameters} screenList={screenParameters} cardsLayoutQuery={cardsLayoutQuery} />;
                        } else {
                            let replacement;
                            for (let i of screenList) {
                                if (i.required && i.param_value === null) {
                                    cardsLayoutQuery = "{}"
                                    break;
                                }
                                if (i.param_value !== null) {
                                    replacement = '\"' + i.param_value + '\"'
                                }
                                else {
                                    replacement = i.param_value
                                }
                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i.param}`, replacement)
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1);
                            return <TenPositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} screenList={screenList} cardsLayoutQuery={cardsLayoutQuery} />;
                        }
                    case ("2cf81c88-03da-4b32-801c-41370d98d633"):
                        if (sidebarScreenParameters) {
                            for (let i of screenParameters) {
                                let replacement = sidebarScreenParameters && sidebarScreenParameters[i];
                                if (typeof replacement === 'string') {
                                    replacement = '\"' + replacement + '\"';
                                }
                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i}`, replacement);
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1);
                            return <OnePositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} sidebarScreenParameters={sidebarScreenParameters} screenList={screenParameters} cardsLayoutQuery={cardsLayoutQuery} />;
                        } else {
                            let replacement;
                            for (let i of screenList) {
                                if (i.required && i.param_value === null) {
                                    cardsLayoutQuery = "{}"
                                    break;
                                }
                                if (i.param_value !== null) {
                                    replacement = '\"' + i.param_value + '\"'
                                }
                                else {
                                    replacement = i.param_value
                                }
                                cardsLayoutQuery = cardsLayoutQuery.replace(`@${i.param}`, replacement)
                            }
                            cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1);
                            return <OnePositionLayoutCard idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} screenList={screenList} cardsLayoutQuery={cardsLayoutQuery} />;
                        }
                    default:
                        return null
                }

            default:
                return null
        }
    }


    return (
        <>
            {card()}
        </>
    );
}

export default NewPanelsContent