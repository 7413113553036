import gql from "graphql-tag";

export const getAnimals = gql`
    query getAnimals($v_locale: String) {
        dal_get_animals_basic_details(args: {v_locale: $v_locale}, limit: 20) {
        animals_genders_name
        animals_species_name
        birth_date
        entities_display_name
        entities_guid
        animals_breeds_name
        animals_colour_name
    }   
}`;

export const getAnimal = gql`
    query getAnimal($entities_guid: uuid) {
        dal_get_animals_basic_details(args: {v_locale: "en"}, where: {entities_guid: {_eq: $entities_guid}}) {
            animals_breeds_guid
            animals_breeds_name
            animals_colour_name
            animals_colours_guid
            animals_genders_guid
            animals_genders_name
            birth_date
            entities_display_name
            animals_species_name
            animals_species_guid
            entities_guid
    }   
}`;

export const getMenus = gql`
    query getMenus($locale: String, $apps_types_uid: uuid, $menus_uid: uuid, $tenants_types_uid: uuid) {
        dal_get_sys_apps_menus(args: {v_locale: $locale, v_sys_apps_types_guid: $apps_types_uid, v_sys_menus_guid: $menus_uid, v_tenants_types_guid: $tenants_types_uid}) {
            gql_results
    }

}`;

export const getUserMenus = gql`
    query getUserMenus( $v_locale: String, $tenants_uid: uuid, $users_uid: uuid, $menus_uid: uuid) {
        dal_get_apps_menu(args: {v_locale: $v_locale, v_sys_menus_guid: $menus_uid, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
            gql_results
            return_code
            return_code_locale
          }
    }
`

export const getPanelsList = gql`
    query getPanelsList( $v_locale: String, $tenants_uid: uuid, $users_uid: uuid) {
        dal_get_apps_panels_list(args: {v_locale: $v_locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
            panel_caption
            panel_description
            panel_name
            panel_order
            users_panels_guid
            users_screens_guid
          }
    }
`

export const getUsersScreen = gql`
    query getUsersScreen( $v_locale: String, $tenants_uid: uuid, $users_uid: uuid, $users_screens_uid: uuid) {
        dal_get_users_screens(args: {v_locale: $v_locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_tenants_guid: $tenants_uid, v_users_guid: $users_uid, v_users_screens_guid: $users_screens_uid}) {
            gql_results
            return_code
            return_code_locale
          }
    }
`

export const getScreensPanels = gql`
    query getScreensPanels( $v_locale: String, $users_screens_uid: uuid) {
        dal_get_sys_screens_panels_list(args: {v_locale: $v_locale, v_sys_screens_guid: $users_screens_uid}) {
            panel_caption
            is_deactivated
            panel_description
            panel_name
            sys_panels_guid
          }
    }
`

export const getTaskStatus = gql`
    query getTaskStatus($tasks_guid: uuid, $locale: String){
        dal_get_task_status(args: {v_locale: $locale, v_tasks_guid: $tasks_guid}) {
            gql_results
            return_code
            return_code_locale
          }
}`;

export const getDashboardSummary = gql`
    query getDashboardSummary{
        demo_dal_dashboard_summary {
            panel_description
            panel_header
            panel_value
            value_units
            value_range_colour
            value_range
          }
}`;

export const getDashboardGovPanelData = gql`
    query getDashboardGovPanelData{
        demo_dal_get_dashboard_gov_panel_data {
            panel_description
            panel_header
            panel_value
            tenants_guid
            value_range
            value_range_colour
            value_range_units
          }
}`;

export const getDogsVaccinationsList = gql`
    query getDogsVaccinationsList($locale: String, $tenants_uid: uuid){
        demo_dal_get_dogs_vaccinations_list(args: {v_locale: $locale, v_tenants_guid: $tenants_uid}, limit: 20) {
            confined_status
            dog_name
            gender
            owned_status
            sterilised
            vaccination_date
            vaccination_product
          }
}`;

export const getCityVaccinationSummary = gql`
    query getCityVaccinationSummary{
        demo_dal_get_city_vaccination_summary {
            sterilised_pct
            total_dogs
            vacc_2018
            vacc_2019
            vacc_2020
            vacc_2021
            vacc_2022
            zone
          }
}`;

export const getDashboardSingleSummary = gql`
    query getDashboardSingleSummary($panel_header: String){
        demo_dal_dashboard_summary(where: {panel_header: {_eq: $panel_header}}) {
            panel_description
            panel_header
            panel_value
            value_range
            value_range_colour
            value_units
          }
}`;

export const getAppEnv = gql`
    query getAppEnv($tenants_uid: uuid, $users_uid: uuid){
        get_app_env(args: {v_auth_guid: null, v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
            gql_results
          }
}`;

export const getUserName = gql`
    query getUserName($users_uid: uuid!){
        users_by_pk(users_guid: $users_uid) {
            user_name
          }
}`;

export const getTenantsDescription = gql`
    query getTenantsDescription($v_locale: String, $users_uid: uuid){
        get_users_tenants(args: {v_locale: $v_locale, v_users_guid: $users_uid}) {
            gql_results
          }
}`;

export const getCatsColoursList = gql`
    query getCatsColoursList{
        dal_get_cats_colours_list(args: {v_locale: "en"}) {
            cats_colour_name
            cats_colours_guid
          }
}`;

export const getCatsBreeds = gql`
    query getCatsBreeds{
        dal_get_cats_breeds_list(args: {v_locale: "en"}) {
            cats_breeds_guid
            cats_breeds_name
          }
}`;

export const getDogsColoursList = gql`
    query getDogsColoursList{
        dal_get_dogs_colours_list(args: {v_locale: "en"}) {
            dogs_colours_guid
            dogs_colours_name
          }
}`;

export const getDogsBreeds = gql`
    query getDogsBreeds{
        dal_get_dogs_breeds_list(args: {v_locale: "en"}) {
            dogs_breeds_guid
            dogs_breeds_name
          }
}`;

export const animalsGenders = gql`
    query animalsGenders{
        dal_get_animals_genders_list(args: {v_locale: "en"}) {
            animals_genders_name
            animals_genders_guid
          }
}`;

export const getSysScreensPanels = gql`
query getSysScreensPanels($screens_uid: uuid){
    dal_get_sys_screens_panels_list(args: {v_locale: "en", v_sys_screens_guid: $screens_uid}) {
        panel_caption
        panel_description
        panel_name
      }
}`;

export const getSysColumnsNames = gql`
query getSysColumnsNames($locale: String){
    sys_columns_names_txn(args: {v_locale: $locale, v_columns_names_csv: null}) {
        column_name
        column_name_display
      }
}`;

export const getAnimalsReport = gql`
query getAnimalsReport($locale: String) {
    dal_get_animals_basic_details(args: {v_locale: $locale}) {
        animals_breeds_name
        animals_colour_name
        animals_genders_name
        animals_species_name
        entities_display_name
      }
}
`;

export const getSysColumnsNamesReport = gql`
query getSysColumnsNames($locale: String, $column_name: String){
    sys_columns_names_txn(args: {v_locale: $locale, v_columns_names_csv: $column_name}) {
        column_name
        column_name_display
      }
}`;

export const getSysMenusList = gql`
query getSysMenusList($locale: String, $tenants_uid: uuid, $apps_type_uid: uuid){
    dal_get_sys_menus_list(args: {v_locale: $locale, v_sys_apps_types_guid: $apps_type_uid, v_tenants_guid: $tenants_uid}) {
        menu_name
        menu_description
        sys_menus_guid
      }
}`;

export const getScreensList = gql`
query getScreensList($locale: String, $tenants_uid: uuid, $users_uid: uuid){
    dal_get_apps_screens_list(args: {v_locale: $locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
        locale
        is_deactivated
        screen_caption
        screen_description
        screen_name
        sys_apps_types_guid
        users_screens_guid
      }
}`;

export const getTagsList = gql`
query getTagsList($v_locale: String, $field_deplyed: Int){
    dal_get_devices_gudi_tag_list(args: {v_field_deployed: $field_deplyed, v_locale: $v_locale})  {
        data_collected
        collar_status
        devices_battery_mgt_name
        devices_collar_status_name
        devices_field_deployed_name
        devices_functional_mode_name
        devices_how_uchip_paired_name
        products_models_name
        products_name
        devices_motion_sensor_name
        devices_idhw_name
        devices_memory_name
        devices_uchip_paired_name
        direction_find
        entities_guid
        field_deployed
        functional_mode
        how_uchip_paired
        idhw
        long_range
        idpet
        memmgt_max_target
        memmgt_scan_mem_write
        memmgt_scan_qualrecord
        memory
        motion_sensor
        reserved
        rssi_bound_beacon
        rssi_bound_dog2dog
        rssi_bound_dogmon
        scanning_duration
        scanning_interval
        serial_id
        tx_level
        uchip_paired
        unique_id
        user_data_raw
        battery_threshold
        battery_mgt
        batch_id
        angle_arrival
        advertising_interval
      }
}`;

export const getUsers = gql`
    query getUsers{
        users {
            user_name
            users_guid
          }
    }
`;

export const getMenuItems = gql`
    query getMenuItems($locale: String, $menu_uid: uuid, $app_types_uid: uuid, $tenants_types_uid: uuid){
        dal_get_sys_apps_menus(args: {v_locale: $locale, v_sys_menus_guid: $menu_uid, v_sys_apps_types_guid: $app_types_uid, v_tenants_types_guid: $tenants_types_uid}) {
            gql_results
            return_code
            return_code_locale
          }
    }
`;

export const getApplicationMenus = gql`
    query getApplicationMenus{
        dal_get_apps_menu(args: {v_locale: "en", v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_sys_menus_guid: "e9773522-76c7-4cb2-bf01-bcc5dd58d72c", v_tenants_guid: "e84c74a9-5bc2-4e6e-8d85-4576da3c3ca2", v_users_guid: "7f18bf8d-7ebc-48d0-ad87-18dc3a0e7c40"}) {
            gql_results
            return_code
            return_code_locale
          }
    }
`;

export const getFileUploadFiles = gql`
    query getFileUploadFiles($file_types_uid: uuid){
        file_upload_files(where: {files_types_guid: {_eq: $file_types_uid}}) {
            file_name
            file_description
            file_size
            is_deactivated
            process_status
            files_guid
            record_create_ts
          }
    }
`;

export const getDevicesContacts = gql`
    query getDevicesContacts($files_uid: uuid){
        tags_data_devices_contacts(where: {files_guid: {_eq: $files_uid}}) {
            device_gateway_unique_id
            device_scanned_unique_id
            device_sync_date
            devices_contacts_guid
            files_guid
            number_scans
            time_first_scan
          }
    }
`;

export const getDevicesGudiAdvert = gql`
    query getDevicesGudiAdvert($files_uid: uuid){
        tags_data_devices_gudi_advert(where: {files_guid: {_eq: $files_uid}}) {
            battery_mgt
            collar_status
            device_gateway_latitude
            device_gateway_longitude
            device_gateway_unique_id
            device_sync_date
            devices_gudi_advert_guid
            idhw
            idpet
            memory
            motion_sensor
            files_guid
            tag_rssi
            tag_meters
            time_recieved
            uchip_paired
            user_data_raw
            functional_mode
            how_uchip_paired
            field_deployed
          }
    }
`;

export const getFileTypes = gql`
    query getFileTypes{
        file_upload_files_types {
            file_type_name
            files_types_guid
          }
    }
`;

export const getFilesList = gql`
    query getFilesList($file_types_uid: uuid, $rows_to_return: Int, $create_ts_end: timestamp, $create_ts_start: timestamp, $tenats_uid: uuid, $users_uid: uuid ) {
        dal_get_files_list(args: 
            {
                v_files_types_guid: $file_types_uid, 
                v_maximum_rows_to_return: $rows_to_return, 
                v_record_create_ts_end: $create_ts_end, 
                v_record_create_ts_start: $create_ts_start, 
                v_tenants_guid: $tenats_uid, 
                v_users_guid: $users_uid}) 
                {
                    file_description
                    file_extension
                    file_mime_type
                    file_name
                    file_size
                    file_type_description
                    file_type_guid
                    file_type_label
                    file_type_name
                    files_guid
                    process_status
            }
    }
`;

export const getDemoProject = gql`
    query getDemoProject{
        dal_get_projects_list {
            projects_guid
            projects_name
          }
    }
`;

export const getDemoViewsList = gql`
    query getDemoViewsList($project_uid: uuid){
        dal_get_projects_views_list(args: {v_project: $project_uid}) {
            latitude
            longitude
            projects_guid
            projects_views_guid
            projects_views_name
            default_magnification
          }
    }
`;

export const getProjectViewList = gql`
    subscription getProjectViewList($project_uid: uuid) {
        dal_get_projects_views_list(args: {v_project: $project_uid}) {
            projects_guid
            projects_views_guid
            projects_views_name
            latitude
            longitude
            default_magnification
    }   
}`;

export const getEventsList = gql`
    query getEventsList($project_uid: uuid){
        demo_dal_get_events_list(args: {v_locale: "en", v_project: $project_uid}) {
            animals_species_name
            eevents_guid
            events_description
            events_latitude
            events_longitude
            events_person_name
            events_types_name
            projects_guid
            projects_name
            tenants_guid
          }
    }
`;

export const GET_THEME_LIST = gql`
    query getThemesList($v_locale: String){
        dal_get_sys_themes_list(args: {v_locale: $v_locale}) {
            is_deactivated
            is_systems
            record_create_ts
            record_id
            record_last_update_ts
            sys_apps_types_guid
            sys_themes_guid
            theme_description
            theme_name
        }
    }
`;

export const getLocalesList = gql`
    query getLocalList($locale: String){
        dal_get_sys_locales_supported_dropdown(args: {v_locale: $locale}) {
            locale
            locale_name_native
            locale_name_system
            sys_locales_supported_guid
          }
    }
`;

export const getThemesModesList = gql`
    query getThemesModesList($locale: String, $themes_guid: uuid){
        dal_get_sys_themes_modes_list(args: {v_locale: $locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_sys_themes_guid: $themes_guid}) {
            sys_themes_modes_guid
            theme_mode_abbreviation
            theme_mode_name
          }
    }
`;


export const getUsersSettingsList = gql`
    query getUsersSettingsList($tenants_uid: uuid, $users_uid: uuid){
        dal_get_users_settings_list(args: {v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
            users_setting
            users_setting_description
            users_setting_value
            users_settings_list_guid
          }
    }
`;

export const getPersonsBasicDetails = gql`
    query getPersonsBasicDetails($locale: String){
        dal_get_persons_basic_details(args: {v_locale: $locale}) {
            date_of_birth
            date_of_death
            entities_display_name
            gender_name
            entities_guid
            note
          }
    }
`;

export const getPersonsNamesList = gql`
    query getPersonsNamesList($locale: String, $entities_uid: uuid, $tenants_uid: uuid, $users_uid: uuid){
        dal_get_persons_names_list(args: {v_locale: $locale, v_entities_guid: $entities_uid, v_persons_names_types_guid: null, v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
            persons_names_types_name
            persons_names_guid
            entities_guid
          }
    }
`;

export const getPersonsNamesLayouts = gql`
    query getPersonsNamesLayouts($locale: String, $entities_uid: uuid, $tenants_uid: uuid, $users_uid: uuid){
        dal_get_persons_names_layouts_overridden_combined_names_list(args: {v_locale: $locale, v_entities_guid: null, v_persons_names_layouts_guid: null, v_persons_names_layouts_templates_guid: null, v_template_locale: $locale, v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
            template_locale
            template_name
            persons_names_types_guid
            persons_names_types_name
            entities_display_name
            name_locale
            layout_name
            combined_name
          }
    }
`;

export const getPersonsNamesLayoutsCustomEntry = gql`
    query getPersonsNamesLayoutsCustomEntry($locale: String, $entities_uid: uuid, $tenants_uid: uuid, $users_uid: uuid){
        dal_get_persons_names_layouts_custom_entry(args: {v_locale: $locale, v_persons_names_guid: $entities_uid, v_tenants_guid: $tenants_uid, v_users_guid: $users_uid, v_persons_names_layouts_guid: null}) {
            name
            order
            is_used
            part_type_name
            suffix
            persons_names_parts_types_guid
            persons_names_parts_guid
            persons_names_layouts_parts_modifiers_guid
            persons_names_layouts_parts_guid
            persons_names_layouts_guid
          }
    }
`;

export const getPersonsNamesLayoutsOverriddenCombined = gql`
    query getPersonsNamesLayoutsOverriddenCombined($locale: String, $entities_uid: uuid, $tenants_uid: uuid, $users_uid: uuid){
        dal_get_persons_names_layouts_overridden_combined_names_list(args: {v_entities_guid: $entities_uid, v_locale: $locale, v_persons_names_layouts_guid: null, v_persons_names_layouts_templates_guid: null, v_template_locale: $locale, v_tenants_guid: $tenants_uid, v_users_guid: $users_uid}) {
            combined_name
            entities_display_name
            template_name
            name_locale
            layout_name
            persons_names_layouts_guid
            persons_names_layouts_templates_guid
            persons_names_types_guid
            persons_names_types_name
            template_locale
            entities_guid
          }
    }
`;

export const getDogsVaccinationReport = gql`
    query getDogsVaccinationReport($year: Int, $name: String ){
        demo_dal_get_dogs_vaccinations(args: {v_year: $year, v_dog_name: $name}) {
            animal_disease
    animals_events_guid
    animals_events_guid2
    animals_events_guid3
    animals_events_guid4
    animals_events_types_name
    body_condition
    caught_by_person_name
    caught_district
    caught_method
    caught_region
    caught_village
    checked_by_person
    coat_condition
    date
    day_number
    distress_level
    dog_checked_date
    dog_immunity_until_date
    dog_name
    dog_vaccination_date
    ear_crusting
    hydration
    immunity_level
    lactating
    mentation
    mobility
    month
    nasal_ocular_discharge
    pregnant
    quarter
    skin_infection
    vaccinating_persons_name
    vaccination_batch_num
    vaccination_organisation_name
    vaccination_product
    vaccination_type
    vaccine_cost_of_vaccination
    year
          }
    }
`;

export const getBangaloreDogVaccinationsReporting = gql`
    query getBangaloreDogVaccinationsReporting{
        demo_dal_get_bangalore_dog_vaccinations_reporting {
            caught_by_person_name
            caught_method
            date
            dog_name
            ngo
          }
    }
`;

export const getDogVaccinationSummary = gql`
    query getDogVaccinationSummary{
        demo_dal_get_dog_vaccination_summary {
            booster_count
            caught_district
            caught_region
            initial_count
            pregnant_count
            total_cost
          }
    }
`;

export const getDogsLicenses = gql`
    query getDogsLicenses{
        demo_dal_get_dog_licenses {
            ward
            vet
            phone_
            pan
            owner_name
            license_number
            license_date
            id
            dogs_rabies_avcc_date
            dogs_name
            dogs_lepto_vacc_date
            dogs_dob
            dogs_colour_name
            dogs_breed_name
            chip_iso
            address
          }
    }
`;

export const CaughtByPerson = gql`
    query CaughtByPerson{
        demo_dal_get_bangalore_dog_vaccinations_reporting {
            caught_by_person_name
            caught_method
            date
            dog_name
            ngo
          }
    }
`;

export const getReport = gql`
    query getReport($report_uid: uuid){
        demo_dal_get_report(args: {v_report_guid: $report_uid}) {
            report_graphql
            report_json
            report_list_guid
            report_name
          }
    }
`;

export const deleteData = gql`
    query deleteData{
        demo_dal_truncate_test_demo_data {
            gql_results
            return_code
            return_code_locale
          }
    }
`;

export const getPanelsDataSource = gql`
    query getPanelsDataSource($locale: String, $panels_uid: uuid, $tenants_types_uid: uuid){
        dal_get_sys_panels_datasource(args: {v_locale: $locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_sys_panels_guid: "83a308d0-e2b1-4035-adf8-8e2dd172d377", v_tenants_types_guid: $tenants_types_uid}) {
            gql_results
            return_code
            return_code_locale
          }        
    }

`

export const getDynamicScreen = gql`
    query getDynamicScreen($locale: String, $screens_uid: uuid, $tenants_uid: uuid, $screen_parameter_values: String, $actions_flags_uid: uuid, $v_users_guid: uuid){
        dal_get_sys_apps_screens(args: {v_locale: $locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_sys_actions_flags_guid: $actions_flags_uid, v_sys_screens_guid: $screens_uid, v_screen_parameter_values: $screen_parameter_values, v_tenants_guid: $tenants_uid, v_users_guid: $v_users_guid}) {
            gql_results
            return_code
            return_code_locale
          }      
    }

`

export const getDynamicScreenSwitch = gql`
    query getDynamicScreenSwitch($locale: String, $screens_paels_uid: uuid, $tenants_uid: uuid, $screen_parameter_values: String, $actions_flags_uid: uuid, $v_users_guid: uuid){
        dal_get_sys_apps_screens_switch(args: {v_locale: $locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_sys_actions_flags_guid: $actions_flags_uid, v_sys_screens_panels_guid: $screens_paels_uid, v_screen_parameter_values: $screen_parameter_values, v_tenants_guid: $tenants_uid, v_users_guid: $v_users_guid}) {
            gql_results
            return_code
            return_code_locale
          }      
    }

`

export const GET_ALERTS = gql`
    query MyQuery {
        alerts: alerts_alerts {
            alert_valid_from
            alert_level
            alert_title
            alert_body
            channel {
            channel_name
            channel_description
        }
  }
}
`

export const GET_ANIMALS_CONTACTS_LIST = gql`
    query MyQuery {
        dal_get_animals_contacts_list (args: {v_locale: "en"}) {
        animals_roaming_types_name,
        towns_villages_name,
        animals_contacts_guid,
        animals_events_medical_treatments_types_name,
        animals_shelter_id,animals_events_medical_diagnostics_types_name,
        animals_owner_contact_phone,
        animals_last_rabies_vaccinated_date,
        animals_owner_first_name,
        animals_sterlisation_types_name,
        shelters_staff_name,
        animals_scanned_device_ble_id,
        animals_contacts_location_point,
        animals_scanned_device_id,
        animals_contact_date,animals_sex_name,
        animals_scanned_animals_guid,
        animals_owner_contact_email,
        animals_scanned_animals_name,
        animals_events_medical_surgeries_types_name,shelter_properties_name,
        animals_age_ranges_name,
        animals_contacts_types_name,
        animals_chip_id,
        animals_identity_file_image,
        animals_owner_address_line1,
        animals_ownership_types_name,
        animals_owner_last_name,vaccinations_rabies_products_name
    }
}
`

// export const getDynamicScreen = gql `
//     query getDynamicScreen($locale: String, $screens_uid: uuid, $tenants_uid: uuid, $screen_parameter_values: String, $actions_flags_uid: uuid){
//         dal_get_sys_apps_screens(args: {v_locale: $locale, v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687", v_sys_actions_flags_guid: $actions_flags_uid, v_sys_screens_guid: $screens_uid, v_screen_parameter_values: $screen_parameter_values, v_tenants_guid: $tenants_uid, v_users_guid: null}) {
//             gql_results
//             return_code
//             return_code_locale
//           }
//     }
//
// `

export const GET_USER_NAME_EMAIL = gql`
    query getUserNameEmail($locale: String, $v_tenants_guid: uuid, $_eq: uuid) {
      dal_get_users_list(args: {v_locale: $locale, v_tenants_guid: $v_tenants_guid}, where: {users_guid: {_eq: $_eq}}) {
        user_name
      }
    }    
`

export const GET_USERS = gql`
    query getUsers($locale: String, $v_tenants_guid: uuid) {
      dal_get_users_list(args: {v_locale: $locale, v_tenants_guid: $v_tenants_guid}) {
        is_deactivated
        is_owner
        role_description
        role_name
        roles_guid
        user_name
        users_guid
        effective_from
      }
    }    
`

export const GET_USERS2 = gql`
    query getUsers2($locale: String, $v_tenants_guid: uuid, $v_users_guid: uuid, $v_user_id: uuid) {
      dal_get_users_list2(args: {v_locale: $locale, v_tenants_guid: $v_tenants_guid, v_users_guid: $v_users_guid}) {
        is_deactivated
        is_owner
        role_description
        role_name
        roles_guid
        user_name
        users_guid
        effective_from
        user_secret_use
      }
    }    
`

export const GET_SINGLE_USER = gql`
    query getSingleUser($locale: String, $v_tenants_guid: uuid, $v_users_guid: uuid, $v_user_id: uuid) {
        dal_get_users_list2(args: {v_locale: $locale, v_tenants_guid: $v_tenants_guid, v_users_guid: $v_users_guid}, where: {users_guid: {_eq: $v_user_id}}) {
            role_name
          }
    }    
`

export const GET_ROLES = gql`
    query getRoles($locale: String, $v_tenants_guid: uuid) {
      dal_get_users_list(args: {v_locale: $locale, v_tenants_guid: $v_tenants_guid}) {
         role_description
        role_name
        roles_guid
        tenants_guid
      }
    }   
`

export const GET_APP_LOGO = gql`
    query getAppLogo {
        dal_get_sys_application_logo {
            logo
            tenants_guid
          }
    }   
`

export const GET_SYS_TENANTS_LIST = gql`
    query getSysTenantsList {
        dal_sys_tenants_list_get(args: {v_locale: "en"}) {
            is_deactivated
            tenant_description
            tenant_name
            tenants_guid
            tenants_type_name
          }
    }   
`

export const GET_SYS_USERS_LIST = gql`
    query getSysUsersList($v_tenants_guid: uuid) {
        dal_sys_users_list_get(args: {v_locale: "en", v_tenants_guid: $v_tenants_guid}) {
            is_deactivated
            role_name
            user_name
            users_guid
        }
    }   
`

export const GET_TENANTS_TYPES_LIST = gql`
    query getTenantsTypesList {
        dal_get_tenants_types_list(args: {v_locale: "en"}) {
            tenants_type_name
            tenants_types_guid
          }
    }   
`

export const GET_SYS_ROLES_LIST = gql`
    query getSysRolesList($v_tenants_guid: uuid) {
        dal_sys_roles_list_get(args: {v_locale: "en", v_tenants_guid: $v_tenants_guid}) {
            is_deactivated
            role_name
            roles_guid
        }
    }   
`

export const GET_SYS_SCREENS_PANELS_JSON = gql`
    query getSysScreensPanelsJSON($v_sys_screens_panels_guid: uuid, $v_sys_panels_view_guid: uuid) {
        dal_get_sys_screens_panels_json(args: {v_locale: "en", v_sys_screens_panels_guid: $v_sys_screens_panels_guid, v_sys_panels_view_guid: $v_sys_panels_view_guid}) {
            gql_results
            return_code
            return_code_locale
          }
    }   
`

export const GET_POLYGONS_FOR_MAP_LIST = gql`
    query getPolygonsForMapList($locale: String, $v_sys_panels_maps_guid: uuid, $v_tenants_guid: uuid) {
        dal_get_polygons_for_map_list(args: {v_locale: $locale, v_sys_panels_maps_guid: $v_sys_panels_maps_guid, v_tenants_guid: $v_tenants_guid}) {
            regions_centroid
            regions_definition
            regions_groups_guid
            regions_groups_name
            regions_guid
            regions_name
            regions_types_guid
            regions_types_name
          }
    }   
`

export const GET_REGIONS_TYPES_LIST = gql`
    query getRegionsTypesList {
        dal_get_regions_types_list(args: {v_locale: "en"}) {
            regions_types_guid
            region_type_name
          }
    }   
`
export const GET_REGIONS_EXPLORER_TYPES_LIST = gql`
    query getRegionsExplorerTypesList($regions_groups_guid: uuid) {
        dal_get_um_regions_explorer_types_list(args: {v_regions_groups_guid: $regions_groups_guid, v_locale: "en"}) {
            instance_guid
            instance_name
            is_checked
            regions_groups_regions_guid
            parent_regions_groups_regions_guid
            row_level
            number_of_children
          }
    }   
`

export const GET_TYPES_EXPLORER_TYPES_LIST = gql`
    query getTypesExplorerTypesList($childRegionType: uuid, $parentRegionType: uuid, $regions_groups_guid: uuid) {
        dal_get_um_regions_explorer_regions_list(args: {v_child_regions_types_guid: $childRegionType, v_locale: "en", v_regions_groups_guid: $regions_groups_guid, v_parent_regions_guid: $parentRegionType}) {
            row_level
            regions_groups_regions_guid
            is_checked
            instance_name
            instance_guid
            parent_regions_groups_regions_guid
            number_of_children
          }
    }   
`

export const GET_SYS_OWNERS_LIST = gql`
    query getSysOwnersList {
        dal_get_sys_owners_list(args: {v_locale: "en"}) {
            is_systems
            name
            sys_owners_guid
          }
    }   
`


export const GET_USER_INFO = gql`
    query getUserInfo {
        dal_get_user_info(args: {v_locale: "en", v_sys_apps_types_guid: "5959df7f-5990-4d7b-9f33-797ed166c687"}) {
            dark_mode
            locale
            sys_themes_guid
            theme_name
            timezone
            user_first_name
            user_last_name
            user_middle_name
            user_name
        }
    }   
`

export const GET_TEST_QUERY = gql`
    query getRegionsList($limit: Int, $offset: Int) {
        dal_get_regions_list(limit: $limit, offset:$offset, args: {v_locale: "en"}) {
            record_id
            record_last_update_ts
            regions_guid
            regions_name
            regions_name_translated
            regions_types_guid
            regions_types_name
            source
        }
    }   
`

export const GET_SCREENS_PANELS_ACTIONS = gql`
    query getScreensPanelsActions($v_locale: String, $v_sys_screens_panels_guid: uuid, $v_tenants_guid: uuid, $v_users_guid: uuid, $v_sys_actions_flags_guid: uuid) {
        dal_get_sys_screens_panels_actions(args: {v_locale: "en", v_sys_screens_panels_guid: $v_sys_screens_panels_guid, v_tenants_guid: $v_tenants_guid, v_users_guid: $v_users_guid, v_sys_actions_flags_guid: $v_sys_actions_flags_guid}) {
            gql_results
        }
    }   
`

