import React, { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { gql, useMutation } from "@apollo/client";
import { downloadFileImage } from '../../apollo/mutations';
import noImage from '../../static/img/no-img.png';
import { SidebarContext } from './Main/context';
import { useSelector, useDispatch } from 'react-redux';

const CardsPanel = ({ panel, cardsQuery, panelJSON }) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [cards, setCards] = useState();
    const [animalImages, setAnimalImages] = useState({});
    const sidebarContext = useContext(SidebarContext);
    const [downloadFileImageMutation, { loading }] = useMutation(downloadFileImage);

    const _getDynamicScreen = async () => {
        if (!cardsQuery) {
            dispatch({ type: 'SAVE_REQUIRED_STATUS_SCREEN', data: true });
        }
        const { data } = await client.query({ query: gql`${cardsQuery}` })
        const queryName = panelJSON.query_name;
        setCards(data[queryName]);
    };

    const getAnimalsImage = async (imgId) => {
        try {
            const { data } = await downloadFileImageMutation({ variables: { files_uid: imgId } });
            return data.fileDownload.file_content;
        } catch (error) {
            console.error('Error downloading file image', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchAnimalImages = async () => {
            if (cards) {
                const imagePromises = cards.map(async (card) => {
                    const imageLocation = await getAnimalsImage(card?.[panelJSON.controls.find(obj => obj.column_order === 28)?.name]);
                    return {
                        id: card?.[panelJSON.controls.find(obj => obj.column_order === 28)?.name],
                        location: imageLocation
                    };
                });
                const images = await Promise.all(imagePromises);
                const animalImageObj = {};
                images.forEach((img) => {
                    animalImageObj[img.id] = img.location;
                });
                setAnimalImages(animalImageObj);
            }
        };
        fetchAnimalImages();
        _getDynamicScreen();
    }, [cards, sidebarContext?.onSubmit]);

    return (
        <div style={{ background: '#f5f5f5', overflow: 'auto', }}>
            <div className="row">
                {
                    cards && cards.map((card, idx) => (
                            <div key={idx} style={{
                                padding: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#fff',
                                border: '1px solid #E0E0E0',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                            }}>
                                {/* Image Section */}
                                {loading ? <p>Loading image...</p> : <img
                                    style={{
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                        marginRight: '16px',
                                        objectFit: 'cover'
                                    }}
                                    alt='Image of pet'
                                    width='120px'
                                    height='120px'
                                    src={`data:image/jpeg;base64,${animalImages[card?.[panelJSON.controls.find(obj => obj.column_order === 28)?.name]] || ''}`}
                                    onError={(e) => {
                                        e.target.onerror = null; // prevent infinite loop
                                        e.target.src = noImage;
                                    }}
                                />}
                                {/* Information Section */}
                                <div style={{ fontSize: '12px', lineHeight: '1.5', flex: 1 }}>
                                    {
                                        panelJSON.controls && panelJSON.controls.map((control, index) => (
                                            !control.hidden ?
                                            <div key={index} style={{ marginBottom: '2px', display: 'flex', border: '1px solid #E0E0E0',  }}>
                                                <div style={{ fontWeight: 'bold', width: '100px' }}>
                                                    {control.label}:
                                                </div>
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        width: '100px'
                                                    }}
                                                    title={card[control.name]}
                                                >
                                                    {card[control.name]}
                                                </div>
                                            </div> :
                                            null
                                        ))
                                    }
                                </div>
                            </div>
                    ))
                }
            </div>
        </div>
    );
};

export default CardsPanel;
